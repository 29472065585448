/*==========================
    Service Area 
===========================*/

.service {
    cursor: pointer; /* This makes the cursor a pointer hand icon when a user hovers over the service */

    // Service Style One
    &.service__style--1 {
        padding: 40px 0;
        @extend %transition;
        @extend %posrelative;
        z-index: 2;

        @media #{$sm-layout} {
            padding: 15px 0;
        }

        @media #{$md-layout} {
            padding: 20px 0;
        }

        .icon {
            img {
                margin-bottom: 34px;

                @media #{$sm-layout} {
                    margin-bottom: 14px;
                    height: 53px;
                }

                @media #{$large-mobile} {
                    margin-bottom: 13px;
                    height: 45px;
                }
            }
        }

        .content {
            h4 {
                &.title {
                    margin-bottom: 20px;
                    font-weight: 400;
                    font-size: 24px;

                    @media #{$sm-layout} {
                        margin-bottom: 12px;
                        font-weight: 400;
                        font-size: 20px;
                    }

                    @media #{$large-mobile} {
                        margin-bottom: 9px;
                        font-size: 18px;
                    }

                }
            }

            p {
                opacity: 0.75;
                font-weight: 300;
            }
        }
    }

    &.service__style--2 {
        padding: 30px 35px;
        @extend %transition;
        @extend %posrelative;
        z-index: 2;
        border-radius: 10px;

        @media #{$laptop-device} {
            padding: 30px 28px;
        }

        @media #{$md-layout} {
            padding: 30px 25px;
        }

        @media #{$sm-layout} {
            padding: 30px 14px;
        }

        @media #{$large-mobile} {
            padding: 30px 18px;
            margin-top: 30px;
        }

        &::before {
            position: absolute;
            content: "";
            left: 0;
            top: 0;
            background-image: linear-gradient(to right, $theme-color-2 , $theme-color-3);
            width: 100%;
            height: 100%;
            z-index: -1;
            opacity: 0;
            visibility: hidden;
            @extend %transition;
            border-radius: 10px;

            @media #{$large-mobile} {
                opacity: 1;
                visibility: visible;
            }
        }

        .icon {
            font-size: 54px;
            font-weight: 400;
            margin-bottom: 23px;
            display: inline-flex;
            color: $theme-color;
            @extend %transition;

            @media #{$md-layout} {
                margin-bottom: 11px;
            }

            @media #{$large-mobile} {
                color: #ffffff;
            }

            svg {
                stroke-width: 1 !important;
            }
        }

        .content {
            h3 {
                &.title {
                    margin-bottom: 19px;
                    font-weight: 500;
                    @extend %transition;

                    @media #{$laptop-device} {
                        font-size: 19px;
                    }

                    @media #{$sm-layout} {
                        font-size: 20px;
                    }

                    @media #{$md-layout} {
                        margin-bottom: 8px;
                    }

                    @media #{$large-mobile} {
                        color: #ffffff;
                    }

                    a {
                        color: inherit;

                        @media #{$large-mobile} {
                            color: #ffffff;
                        }
                    }
                }
            }

            p {
                @extend %transition;
                color: rgba(29, 29, 36, 0.75);

                @media #{$large-mobile} {
                    color: #ffffff;
                }

            }
        }

        &:hover {
            box-shadow: 0 10px 25px 10px rgba(0, 0, 0, 0.1);
            transform: translateY(-5px);

            &::before {
                opacity: 1;
                visibility: visible;
            }

            .icon {
                color: #ffffff;
            }

            .content {
                h3 {
                    &.title {
                        @extend %textwhite;
                    }
                }

                p {
                    @extend %textwhite;
                }
            }
        }

        &.service-left-align {
            display: flex;
            padding: 50px 24px;

            .icon {
                padding-right: 20px;
            }
        }
    }



    // Service Style Four 
    &.service__style--4 {
        padding: 53px 40px;
        z-index: 2;
        border-radius: 10px;
        position: relative;
        @extend %transition;
        background: #191919;
        height: 100%;
        cursor: pointer;

        @media #{$laptop-device} {
            padding: 36px 25px;
        }

        @media #{$lg-layout} {
            padding: 25px 20px;
        }

        @media #{$md-layout} {
            padding: 30px 25px;
        }

        @media #{$sm-layout} {
            padding: 25px 20px;
        }

        &::before {
            position: absolute;
            content: "";
            left: 0;
            top: 0;
            background-image: -webkit-gradient(linear, left top, right top, from(#f61b10), to(#ef0963));
            background-image: -webkit-linear-gradient(left, #f61b10, #ef0963);
            background-image: linear-gradient(90deg, #f61b10, #ef0963);
            width: 100%;
            height: 100%;
            z-index: -1;
            opacity: 0;
            visibility: hidden;
            border-radius: 10px;
            @extend %transition;

            @media #{$large-mobile} {
                opacity: 1;
                visibility: visible;
            }
        }

        .icon {
            display: inline-flex;
            font-size: 54px;
            font-weight: 400;
            margin-bottom: 23px;
            display: inline-flex;
            color: $theme-color;
            @extend %transition;

            @media #{$sm-layout} {
                margin-bottom: 18px;
            }

            @media #{$large-mobile} {
                color: #fff;
            }

            svg {
                stroke-width: 1 !important;
            }
        }

        .content {
            h3 {
                &.title {
                    margin-bottom: 19px;
                    font-weight: 500;
                    font-size: 22px;
                    @extend %transition;

                    @media #{$laptop-device} {
                        margin-bottom: 12px;
                        font-size: 20px;
                    }

                    @media #{$large-mobile} {
                        color: #fff;
                    }

                    @media #{$sm-layout} {
                        margin-bottom: 10px;
                        font-size: 18px;
                    }
                }
            }

            p {
                color: rgba(29, 29, 36, .75);
                @extend %transition;

                @media #{$large-mobile} {
                    color: #fff;
                }
            }
        }

        &:hover {
            box-shadow: 0 10px 25px 10px rgba(0, 0, 0, .1);
            -webkit-transform: translateY(-5px);
            transform: translateY(-5px);

            &::before {
                opacity: 1;
                visibility: visible;
            }

            .icon {
                color: #fff;
            }

            .content {
                h3 {
                    &.title {
                        color: #fff;
                    }
                }

                p {
                    color: #fff;
                }
            }
        }
    }



}

/* ---------------------------
    Standard Service  
-------------------------------*/
.standard-service {
    .thumbnai {
        img {
            border-radius: 5px;
        }
    }

    .content {
        h3 {
            font-size: 19px;
            margin-top: 30px;
            margin-bottom: 10px;
            font-weight: 600;

            a {
                @extend %transition;
                color: $heading-color;

                &:hover {
                    color: $theme-color;
                }
            }
        }

        p {
            font-size: 15px;
            line-height: 24px;
        }

        a {}
    }
}



.text-center,
.text-left {
    .service {
        &.service__style--2 {
            padding: 60px 45px;

            @media #{$sm-layout} {
                padding: 30px 14px;
            }

            @media #{$lg-layout} {
                padding: 30px 14px;
            }

            @media #{$md-layout} {
                padding: 30px 14px;
            }
        }
    }
}

// Sercvice Style One
.service-white {

    // Service Style One
    .service__style--1 {
        z-index: 2;

        .content {
            h4 {
                &.title {
                    color: #c6c9d8;
                }
            }

            p {
                color: #c6c9d8;

            }
        }
    }
}

/*---------------------------
    Paralax Service Style  
-----------------------------*/

.rn-paralax-service {
    .service__style--2 {
        z-index: 2;
        padding: 40px 40px;
        background: rgba(255, 255, 255, 0.3);
        margin-top: 40px;
        overflow: hidden;
        border: 2px solid transparent;

        @media #{$sm-layout} {
            padding: 30px 20px;
            background: transparent;
            margin-top: 30px;
        }

        .icon {
            color: #ffffff;
        }

        .content {
            h3 {
                &.title {
                    font-size: 19px;
                    color: #ffffff;
                }
            }

            p {
                color: rgba(255, 255, 255, 0.75);
            }
        }

        &:hover {
            border: 2px solid $theme-color;

            &::before {
                border-radius: 0;
            }
        }
    }
}

/* Creative Agency Service  */
.creative-service-wrapper {
    .row {
        &.creative-service {
            a {
                display: flex;
                height: 100%;
            }

            .service {

                &.service__style--2 {
                    margin-top: 30px;
                    background: #f6f6f6;
                }
            }
        }
    }

}



.service-one-wrapper {
    @media #{$md-layout} {
        margin: 0 -40px;
    }
}



.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    /* Make sure it's above other content */
}


.modal-content {
    background: white;
    padding: 40px;
    border-radius: 4px;
    position: relative;
    display: flex;
    flex-direction: column; /* Stack children vertically */
    align-items: center; /* Center items */
    max-width: 25vw; /* Adjusted width for main image/video */
}


.modal-close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    border: none;
    background: none;
    font-size: 1.5rem;
    cursor: pointer;
}


.image-gallery {
    display: flex; /* Use flexbox for the gallery */
    flex-wrap: wrap; /* Allow wrapping of items */
    gap: 10px; /* Space between images and video */
    justify-content: flex-start; /* Adjust if necessary */
}

.image-gallery img,
.service-video {
    /* Maintain aspect ratio */
    max-width: 100%;
    /* Ensure it doesn't overflow the modal */
    max-height: 80%;
    /* 80% of the modal-content height */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    /* Optional: Adds a shadow for depth */
    border-radius: 4px;
    /* Optional: Rounds the corners of images and video */
    flex: 1 0 auto; /* Flex-grow, flex-shrink, and flex-basis */
    width: 25vw; /* Adjust this value as needed */
    height: auto; /* Maintain aspect ratio */
}

.main-display {
    /* ... existing styles ... */
    position: relative; /* For absolute positioning of arrows */
}

.main-display img, .main-display video {
    width: 100%; /* Make image/video fill the container */
    height: auto; /* Maintain aspect ratio */
}

.thumbnails {
    display: flex;
    overflow-x: auto; /* Enable horizontal scrolling for thumbnails */
}

.thumbnails img {
    width: 50px; /* Small thumbnail size */
    height: auto;
    cursor: pointer; /* Indicate clickable */
    opacity: 0.6; /* Dull thumbnails a bit */
    transition: opacity 0.3s; /* Smooth transition for hover effect */
}

.thumbnails img.active, .thumbnails img:hover {
    opacity: 1; /* Highlight the active or hovered thumbnail */
}
/* Adjusting the width for mobile devices */
@media (max-width: 768px) { /* Adjust this value based on the breakpoint you want to use for mobile devices */
    .modal-content {
        max-width: 65vw; /* 65% of the viewport width */
        padding: 20px; /* Adjust padding for smaller screens if needed */
    }

    .main-display img, .main-display video {
        width: 100%; /* This ensures it fills the modal content area, which is now 65% of the viewport width */
    }

    .thumbnails img {
        width: 30px; /* Adjust thumbnail size for mobile if desired */
    }
}